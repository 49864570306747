import React from "react";
import { CartIcon } from '@icons/material';

const ProductLink = ({ children, ...attr }) => {
    return (
        <span className="product-link" style={{ display: "block", marginTop: "24px" }}>
            <a className="button is-primary is-light is-medium is-rounded" target="_blank" {...attr}>
                <span>{ children }</span>
                <span class="icon is-small">
                    <CartIcon />
                </span>
            </a>
        </span>
    );
};

export default ProductLink;
