import * as React from "react";
import PropTypes from "prop-types";
import rehypeReact from "rehype-react"
import ProductLink from "./ProductLink";
 
const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { "product-link": ProductLink }
}).Compiler;

export const HTMLContent = ({ content, className }) => (
  <div className={className}>{renderAst(content)}</div>
);

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
);

Content.propTypes = {
  content: PropTypes.object,
  className: PropTypes.string,
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
